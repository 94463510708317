import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import Logo from './partials/Logo';
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
// import FooterNav from './partials/FooterNav';
import FooterSocial from "./partials/FooterSocial";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-top space-between text-xs ">
            <div className="footer-text">
              <b>
                <div className="footer-sub-columns">
                  <LocationOnIcon className="footer-sub-icons" />
                  Metropolitan House 401,<br></br>
                  Artemidos Avenue 33,<br></br>
                  6025, Larnaca, Cyprus
                </div>
                <div className="footer-sub-columns">
                  <WatchLaterIcon className="footer-sub-icons" />
                  Monday-Friday:<br></br>
                  08:00 AM - 18:00 PM
                </div>
                <div className="footer-sub-columns">
                  <EmailIcon className="footer-sub-icons" />
                  andreas@thetensortech.com
                </div>
                <div className="footer-sub-columns">
                  <PhoneIcon className="footer-sub-icons" />
                  +357 99980377
                </div>
                <div className="footer-social">
                  <a href="https://www.linkedin.com/company/72422263/" style={{padding: "4px", width: "fit-content"}}>
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg">
                      <title>LinkedIn</title>
                      <g>
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                      </g>
                    </svg>
                  </a>
                </div>
              </b>
            </div>
            <FooterSocial />
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <div className="footer-text">
              © 2022 All rights reserved. KPSTENSORTECH LTD
            </div>
            {/* <FooterNav /> */}

            <Logo width={300} height={63} />
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
